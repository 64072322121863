import * as types from 'store/types/answers';

export const get = (projectId) => ({
  type: types.GET,
  projectId,
});

export const set = (payload) => ({
  type: types.SET,
  payload
});

export const deleteAnswer = (id) => ({
  type: types.DELETE_ANSWER,
  id
});

export const removeAnswer = (id) => ({
  type: types.REMOVE_ANSWER,
  id
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const downloadDataInSquadFormat = (projectId, isMyLabels = false) => ({
  type: types.DOWNLOAD_DATA_IN_SQUAD_FORMAT,
  projectId,
  isMyLabels
});

export const downloadDataInCSVFormat = (projectId, isMyLabels = false) => ({
  type: types.DOWNLOAD_DATA_IN_CSV_FORMAT,
  projectId,
  isMyLabels
});

export const reset = () => ({
  type: types.RESET,
});
