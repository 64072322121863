import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table, Button, Popconfirm, Menu, Icon, Dropdown, Empty } from 'antd';
import XLSX from 'xlsx';
import { createLink } from 'core/utils/links';
import styles from './styles.module.scss';

class AnswersTab extends PureComponent {
  static propTypes = {
    entries: PropTypes.array,
    projectId: PropTypes.string,
    isMyLabels: PropTypes.bool,
    userId: PropTypes.number,
    title: PropTypes.string,
    downloadDataInSquadFormat: PropTypes.func,
    downloadDataInCSVFormat: PropTypes.func,
    onAnswerDelete: PropTypes.func,
    emptyTableDescription: PropTypes.object,
  };

  static defaultProps = {
    entries: [],
    projectId: '',
    isMyLabels: false,
    userId: 0,
    title: 'Labels page',
    downloadDataInSquadFormat: () => {},
    downloadDataInCSVFormat: () => {},
    onAnswerDelete: () => {},
    emptyTableDescription: <span>No data.</span>,
  };

  renderColumns = () => {
    const {
      projectId,
      onAnswerDelete
    } = this.props;

    return [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Question text',
        dataIndex: 'question',
        key: 'question',
        width: 500
      },
      {
        title: 'Answer',
        dataIndex: 'selected_text',
        key: 'selected_text',
        align: 'left',
        className: styles.answerCol
      },
      {
        title: 'Actions',
        key: 'action',
        align: 'center',
        render: el => {
          return (
            <div className={styles.buttons}>
              <Link to={createLink('document', {
                projectId,
                documentId: el.document_id
              })}
              >
                <Button shape="circle" size="small">
                  <i className="icon-arrow-right" />
                </Button>
              </Link>
              <Popconfirm
                placement="topRight"
                title={'Are you sure to delete this answer?'}
                onConfirm={onAnswerDelete(el.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" size="small">
                  <i className="icon-delete" />
                </Button>
              </Popconfirm>
            </div>
          );
        },
        width: 120,
      },
    ];
  };

  exportAnswersTable = () => {
    const {
      entries,
      isMyLabels,
      userId
    } = this.props;
    const columnsTitle = [
      'ID',
      'Question Text',
      'Category',
      'Answer',
    ];

    const filteredEntries = [];
    entries.forEach(({ id, question, answer_category, selected_text, user_id }) => {
      if (!isMyLabels || (isMyLabels && user_id === userId)) {
        filteredEntries.push([id, question, answer_category, selected_text]);
      }
    });

    const ws = XLSX.utils.aoa_to_sheet([
      columnsTitle,
      ...filteredEntries
    ]);
    const wb = XLSX.utils.book_new();

    ws['!cols'] = [{ wpx: 80 }, { wpx: 500 }, { wpx: 500 }];

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet');
    XLSX.writeFile(wb, 'answers.xlsx');
  };

  render() {
    const {
      downloadDataInSquadFormat,
      downloadDataInCSVFormat,
      entries,
      projectId,
      isMyLabels,
      userId,
      title,
      emptyTableDescription,
    } = this.props;

    const dropDownMenu = (
      <Fragment>
        <Menu>
          <Menu.Item>
            <span onClick={this.exportAnswersTable}>
              Export table in excel
            </span>
          </Menu.Item>
          <Menu.Item>
            <span onClick={() => downloadDataInCSVFormat(projectId, isMyLabels)}>
              Export table in CSV
            </span>
          </Menu.Item>
          <Menu.Item>
            <span onClick={() => downloadDataInSquadFormat(projectId, isMyLabels)}>
              Export in squad format
            </span>
          </Menu.Item>
        </Menu>
      </Fragment>
    );

    const filteredEntries = [];

    entries.forEach((item, index) => {
      if (!isMyLabels || (isMyLabels && item.user_id === userId)) {
        filteredEntries.push({ ...item, key: item.id, index });
      }
    });

    return (
      <Table
        bordered
        columns={this.renderColumns()}
        dataSource={filteredEntries}
        pagination={{
          pageSize: 30,
          total: entries.length,
        }}
        title={() => (
          <div className={styles.tableHeader}>
            <h2 className="h2">{ title }</h2>

            <Dropdown
              disabled={!entries.length}
              overlay={dropDownMenu}
            >
              <Button type="primary">Export answers <Icon type="down" /></Button>
            </Dropdown>
          </div>
        )}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyTableDescription} />
        }}
      />
    );
  }
}

export default AnswersTab;
