import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import fileSaver from 'file-saver';
import XLSX from 'xlsx';
import * as api from 'store/sagas/api';
import * as types from 'store/types/answers';
import * as actions from 'store/actions/answers';

export function* get ({ projectId }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield api.get(`/projects/${ projectId }/answers`);
    yield put(actions.set(data));
  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* deleteAnswer ({ id }) {

  try {
    yield put(actions.removeAnswer(id));

    yield api.del(`/answers/${id}`);

  } catch (error) {
    yield put(actions.get());
    message.error(error.message);
  }
}

export function* getDataInSquadFormat ({ projectId, isMyLabels }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield api.get(`/projects/${ projectId }/export/squad`, { isMyLabels });
    const dataInJson = JSON.stringify(data, null, 2);
    const blob = new Blob([dataInJson], { type: 'application/json' });
    fileSaver.saveAs(blob, 'answers');
  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getDataInCSVFormat ({ projectId, isMyLabels }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const data = yield api.get(`/projects/${ projectId }/export/csv`, { isMyLabels });
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();

    ws['!cols'] = [{ wpx: 80 }, { wpx: 500 }, { wpx: 500 }];

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet');
    XLSX.writeFile(wb, 'answers.csv');
  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([
    yield takeLatest(types.GET, get),
    yield takeLatest(types.DOWNLOAD_DATA_IN_SQUAD_FORMAT, getDataInSquadFormat),
    yield takeLatest(types.DOWNLOAD_DATA_IN_CSV_FORMAT, getDataInCSVFormat),
    yield takeEvery(types.DELETE_ANSWER, deleteAnswer),
  ]);
}
